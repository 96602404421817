<template>
  <div class="box" style="font-size: 16px;">
    <!-- 顶部介绍 -->
    <div class="top" style="padding: 0 15px; height:234px;">
      <div class="tag" style="display: none;width:100px;height:30px; border-top-left-radius: 13px; border-bottom-left-radius: 13px;">榜单 | 规则 </div>
      <div class="title" style="margin-top:40px;font-size:24px;letter-spacing: 3px;"> 分享有礼 赠课领红包</div>
      <div class="subtitle" style="margin-top:15px;font-size:15px">分享课程到朋友圈，赠课给好友，好友领取课程后，即可领取现金红包</div>
      <div class="scroll" style="margin :10px 0">
        <ul>
          <li style="margin-top:10px;">
            <div class="my-earnings" style="display: flex;align-items: center;">
              <img :src="headImg" alt=""
                style="width:19px;height:19px;background-color:#fff;border-radius: 50%;margin-top:2px;">
              <span class="text" style="font-size:15px">每***牙获得返现44元</span>
            </div>
          </li>
          <li style="margin-top:10px;">
            <div class="my-earnings" style="display: flex;align-items: center;">
              <img :src="headImg" alt=""
                style="width:19px;height:19px;background-color:#fff;border-radius: 50%;margin-top:2px;">
              <span class="text" style="font-size:15px">只***云获得返现35元</span>
            </div>
          </li>
          <li style="margin-top:10px;">
            <div class="my-earnings" style="display: flex;align-items: center;">
              <img :src="headImg" alt=""
                style="width:19px;height:19px;background-color:#fff;border-radius: 50%;margin-top:2px;">
              <span class="text" style="font-size:15px">梦***思获得返现24元</span>
            </div>
          </li>
          <li style="margin-top:10px;">
            <div class="my-earnings" style="display: flex;align-items: center;">
              <img :src="headImg" alt=""
                style="width:19px;height:19px;background-color:#fff;border-radius: 50%;margin-top:2px;">
              <span class="text" style="font-size:15px">天***阔获得返现74元</span>
            </div>
          </li>
          <li style="margin-top:10px;">
            <div class="my-earnings" style="display: flex;align-items: center;">
              <img :src="headImg" alt=""
                style="width:19px;height:19px;background-color:#fff;border-radius: 50%;margin-top:2px;">
              <span class="text" style="font-size:15px">一***得获得返现54元</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 奖励相关数据盒子 -->
    <div class="award-box" style="padding:20px 20px">
      <div style="color:rgb(107 105 105);font-size:15px;">现金奖励(元)</div>
      <div style="display:flex;justify-content: space-between;margin-top:10px;">
        <div style="font-size:24px;font-weight:700">{{distributor.data.total_income - distributor.data.total_exp}} <span @click="goDetail" style="font-size:14px;font-weight:700;color:#d9974b">明细</span></div>
        <div style=" display: none;width: 90px;height: 30px;background-color: #fd8e23;color:#fff;display: none;justify-content: center;padding-top: 3px;border-radius: 25px;">提现</div>
      </div>
      <div style="display: flex;align-items: center;margin-top:8px;color:#b1b1b1;font-size:15px">可全部提现
        <div
            style="display: flex;justify-content: center;align-items: center; font-size:12px;width: 14px;height:14px;border-radius:50%;border: 1px solid #b1b1b1;">
          ?
        </div>
      </div>
      <div style="margin-top:20px; height:1px;background-color: #eae8e8;"></div>
      <div style="display: flex;justify-content: space-around;margin-top:15px;">
        <div class="flex">
          <div style="color:#b1b1b1;font-size:14px;">累计返现</div>
          <div style="font-size:17px;font-weight:700;margin-top:5px;">{{ distributor.data.total_income }}</div>
        </div>
        <!-- <div class="flex">
          <div style="color:#b1b1b1;font-size:14px;">邀请人数</div>
          <div style="font-size:17px;font-weight:700;margin-top:5px;">{{distributor.data.total_rel_num}}</div>
        </div> -->
        <div class="flex" style="margin-left:0%">
          <div style="color:#b1b1b1;font-size:14px;">有效人数</div>
          <div style="font-size:17px;font-weight:700;margin-top:5px;">{{ distributor.data.valid_total_rel_num }}</div>
        </div>
        <!-- <div class="flex">
          <div style="color:#b1b1b1;font-size:14px;">待确认人数</div>
          <div style="font-size:17px;font-weight:700;margin-top:5px;">{{distributor.data.total_rel_num - distributor.data.valid_total_rel_num }}</div>
        </div> -->
      </div>
    </div>
    <!-- 推荐分享盒子 -->
    <div class="share-box" style="padding-top:20px;padding-bottom:30px;">
      <div style="display:flex">
        <div
            style="width:7px;height:20px;margin-top:1px;background-color: #fc8715;border-top-right-radius: 50%;border-bottom-right-radius: 50%;"></div>
        <div style="display:flex;width:100%;justify-content:space-between;padding-left:10px;">
          <div style="font-size:16px;font-weight:700;">推荐分享</div>
          <div style="font-size:14px;color:#8c8c8c;margin-right:15px;">分类选课</div>
        </div>
      </div>
      <div style="padding-left:17px;">
        <!-- 讲师课程card -->
        <div style="margin-top:20px;display:flex" v-for="(item,index) in distributor.recommend" :key="index">
          <div style="width:90px;min-width:95px;height:90px;border-radius:4px;overflow: hidden;" @click="jump(item.link)">
            <img style="width:100%;height:100%;" :src="item.headImg" alt="">
          </div>
          <div style="width:100%;display:flex;flex-direction: column;justify-content: space-between;margin-left:12px;" @click="jump(item.link)">
            <div style="width:100%;height:100%;">
              <div style="font-weight:700">{{ item.title }}</div>
              <div style="display:flex;margin-top:4px;width:100%;height:100%;">
                <div class="skill"
                     style="width:100%;height:100%;display: flex;padding: 0 5px;font-size:12px;margin-right: 10px; ">
                  {{ textFn(item.desc,65)  }}
                </div>
                <!-- <div class="skill" style="height: 24px;display: flex;align-items: center;padding: 0 5px;font-size:12px;margin-right: 10px; ">计算机博士，前Airbnb...</div> -->
              </div>
            </div>
            <div style="display: none;color:#ea8e37;font-size:13px;" >成功邀请赚20元</div>
          </div>
        </div>
      </div>
    </div>

    <div style="height:80px;"></div>
  </div>
</template>

<script>
import { getDistributorTotal } from '../api/distributor'

export default {
  data() {
    return {
      headImg: 'https://ceshiren.com/uploads/default/original/1X/809c63f904a37bc0c6f029bbaf4903c27f03ea8a.png',
      distributor: {
        "recommend": [],
        "data": {
          'total_rel_num': 0,
          'valid_total_rel_num': 0,
          'total_income': 0,
          'total_exp': 0,
        }
      },
      unionid: "",
    }

  },
  async created() {
    this.unionid = this.$route.query.unionid
    const res = await getDistributorTotal({ union_id: this.unionid })
    console.log(res);
    this.distributor = res.data
  },
  methods: {
    jump(link) {
      window.open(link)
    },
    goDetail() {
      this.$router.push({ path: '/distributor-details', query: { unionid: this.unionid } })
    },
    //文字过长处理
    textFn(text, num) {
      if (text && text.length > num) {
        return text.substring(0, num) + '···'
      } else {
        return text
      }
    },
  }
}
</script>

<style scoped>
.box div {
  box-sizing: border-box;
}

.box {
  background-color: #ecedf0;
}

.top {
  /* background: linear-gradient(#fa891c 42%, #fff 32%); */
  background: linear-gradient(to bottom, #f8881f, #d4a277);
  position: relative;
}

.tag {
  position: absolute;
  top: 30%;
  right: 0;
  background-color: #ffa440;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(241, 240, 240);
}

.title {
  display: inline-block;
  color: #fff;
}

.subtitle {
  color: rgb(226, 224, 224);
  margin-top: 10px;
}

.text {
  color: rgb(226, 224, 224);
}

.award-box {
  width: 94%;
  background-color: #fff;
  margin: -30px auto 0;
  position: relative;
  border-radius: 10px;
}

.share-box {
  width: 94%;
  background-color: #fff;
  margin: -30px auto 0;
  border-radius: 10px;
  margin-top: 20px;
}

.skill {
  /* background-color: #f7f7f9; */
}

.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.scroll {
  overflow: hidden;
  height: 50px;
}

.scroll ul {
  animation: move 8s linear infinite;
}
.scroll ul li {
  list-style: none;
}

@keyframes move {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-85%);
  }
}
</style>